function loadScript() {
    import("./gs.js");
}

let Module;

export function _GSPS2PDF(
    dataStruct,
    responseCallback,
    progressCallback,
    statusUpdateCallback,
) {
    // first download the ps data
    fetch(dataStruct.psDataURL).then((response) => {
        // read the data as an array buffer
        return response.arrayBuffer();
    }).then((arrayBuffer) => {
        // release the URL
        window.URL.revokeObjectURL(dataStruct.psDataURL);
        window.InputBuffer = new Uint8Array(arrayBuffer) // avoid closure issues
        if (window.Module) {
            Module["calledRun"] = false; // bypass check
            Module["run"]()
            return
        }
        //set up EMScripten environment
        Module = {
            preRun: [
                function () {
                    const FS = window.FS;
                    var data = FS.writeFile("input.pdf", window.InputBuffer);
                },
            ],
            postRun: [
                function () {
                    const FS = window.FS;
                    var uarray = FS.readFile("output.pdf", { encoding: "binary" }); //Uint8Array
                    var blob = new Blob([uarray], { type: "application/octet-stream" });
                    var pdfDataURL = window.URL.createObjectURL(blob);
                    responseCallback({ pdfDataURL: pdfDataURL, url: dataStruct.url });
                },
            ],
            arguments: [
                "-sDEVICE=pdfwrite",
                "-dCompatibilityLevel=1.4",
                "-dPDFSETTINGS=/ebook",
                "-DNOPAUSE",
                "-dQUIET",
                "-dBATCH",
                "-sOutputFile=output.pdf",
                "input.pdf",
            ],
            print: function (text) {
                statusUpdateCallback(text);
            },
            printErr: function (text) {
                statusUpdateCallback("Error: " + text);
                console.error(text);
            },
            setStatus: function (text) {
                if (!Module.setStatus.last)
                    Module.setStatus.last = { time: Date.now(), text: "" };
                if (text === Module.setStatus.last.text) return;
                var m = text.match(/([^(]+)\((\d+(\.\d+)?)\/(\d+)\)/);
                var now = Date.now();
                if (m && now - Module.setStatus.last.time < 30)
                    // if this is a progress update, skip it if too soon
                    return;
                Module.setStatus.last.time = now;
                Module.setStatus.last.text = text;
                if (m) {
                    text = m[1];
                    progressCallback(false, parseInt(m[2]) * 100, parseInt(m[4]) * 100);
                } else {
                    progressCallback(true, 0, 0);
                }
                statusUpdateCallback(text);
            },
            noExitRuntime: true,
            totalDependencies: 0,
        };
        Module.setStatus("Loading Ghostscript...");
        window.Module = Module;
        loadScript();
    });
}

const fileInput = document.getElementById('file-input');
const fileList = document.getElementById('file-list');
const uploadBtn = document.getElementById('upload-btn');
const downloadBtn = document.getElementById('download-btn');

fileInput.addEventListener('change', updateFileList);
uploadBtn.addEventListener('click', uploadFiles);

function updateFileList() {
    fileList.innerHTML = '';
    uploadBtn.disabled = fileInput.files.length === 0;

    Array.from(fileInput.files).forEach((file, index) => {
        const li = createDraggableListItem(file, index);
        fileList.appendChild(li);
    });
    downloadBtn.classList.add('hidden');
}

function createDraggableListItem(file, index) {
    const li = document.createElement('li');
    li.className = 'py-2 px-3 bg-gray-50 rounded mb-2 text-sm text-gray-700 cursor-move';
    li.textContent = `${file.name} (${formatFileSize(file.size)})`;
    li.draggable = true;
    li.dataset.index = index;

    li.addEventListener('dragstart', dragStart);
    li.addEventListener('dragover', dragOver);
    li.addEventListener('drop', drop);
    li.addEventListener('dragenter', dragEnter);
    li.addEventListener('dragleave', dragLeave);

    return li;
}

function dragStart(e) {
    e.dataTransfer.setData('text/plain', e.target.dataset.index);
    e.target.classList.add('bg-blue-100');
}

function dragOver(e) {
    e.preventDefault();
}

function dragEnter(e) {
    e.target.classList.add('bg-yellow-100');
}

function dragLeave(e) {
    e.target.classList.remove('bg-yellow-100');
}

function drop(e) {
    e.preventDefault();
    const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
    const toIndex = parseInt(e.target.dataset.index);

    if (fromIndex !== toIndex) {
        const files = Array.from(fileInput.files);
        const [movedFile] = files.splice(fromIndex, 1);
        files.splice(toIndex, 0, movedFile);

        const newFileList = new DataTransfer();
        files.forEach(file => newFileList.items.add(file));
        fileInput.files = newFileList.files;

        updateFileList();
    }

    e.target.classList.remove('bg-yellow-100');
}

function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

function uploadFiles() {
    let fileUrls = []
    for (const file of fileInput.files) {
        const url = window.URL.createObjectURL(file)
        fileUrls.push(url)
    }
    // console.log(fileUrls, fileInput.files)

    compressPDF(fileUrls[0], fileInput.files[0].name)

    // Clear the file input and list after upload
    // fileInput.value = '';
    // updateFileList();
}

function downloadFile(blobURL, fileName) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = fileName;

    // Append to the document, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the object URL
    URL.revokeObjectURL(blobURL);
}

function compressPDF(pdf, filename) {
    const dataObject = { psDataURL: pdf };
    _GSPS2PDF(
        dataObject,
        (element) => {
            loadPDFData(element, filename).then(({ pdfURL }) => {
                // console.log({ pdfURL });
                setDownloadLink(pdfURL);
            });
        },
        (...args) => console.log("Progress:", JSON.stringify(args)),
        (element) => console.log("Status Update:", JSON.stringify(element)),
    );
}

function setDownloadLink(pdfURL) {
    downloadBtn.disabled = false;
    downloadBtn.classList.remove("hidden")
    downloadBtn.onclick = () => downloadFile(pdfURL, "compressed.pdf");
}

function loadPDFData(response, filename) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", response.pdfDataURL);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
            window.URL.revokeObjectURL(response.pdfDataURL);
            const blob = new Blob([xhr.response], { type: "application/pdf" });
            const pdfURL = window.URL.createObjectURL(blob);
            resolve({ pdfURL });
        };
        xhr.send();
    });
}